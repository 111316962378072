<template>
    <div>
        <div class="row">
            <div class="col-8 img-magnifier-container pos-relative" v-if="!loading">
                <secure-image style="display: block; width: 100%; height: auto;" :endpoint="'/api/event/image/'" :id="'event_image'" :filename="event.image" :site_id="event.site_id" :storage_account="event.storage_account" :magnify="true"></secure-image>
<!--                <img id="event_image"  @load="magnify('event_image', 3);" :src="'https://api.varsanpr.com/test/admin/events/' + event.id + '/image?token='+socket_token+'&site_id=' + event.site_id">-->
            </div>
            <div class="col-12" v-show="loading">
                <loader :show="loading"></loader>
            </div>
            <div class="col-4" v-show="!loading">
                <div class="row">
                    <div v-if="!loading" class="col-12 text-center">
                        <div class="number-plate number-plate-gbr">{{vehicle.plate}}</div>
                    </div>
                    <div class="col-12 mg-t-10" style="overflow-x: hidden;">
                        <canvas id="plateCanvas" style="margin-left: auto; margin-right: auto;" :width="(plateHighlight.width * plateHighlight.magnify) + 'px'" :height="(plateHighlight.height * plateHighlight.magnify) + 'px'"></canvas>
                    </div>
                    <div v-if="!loading" class="col-12 mg-t-10">
                        <div v-if="national.by.length > 0" class="card card-body tx-white-8 bg-danger bd-0 text-center">
                            <p style="font-size: 20px; margin-bottom: 0px;"><strong>BLACKLISTED BY</strong></p>
                            <p style="font-size: 20px; margin-bottom: 0px;" v-for="entry in national.by">{{entry.name}} for <span class="tx-medium">{{entry.reason || "Unknown"}}</span></p>
                        </div>

                        <div v-if="watchlist.reason !== null" v-bind:class="{'mg-t-20': national.by.length}" class="card card-body tx-white-8 bg-danger bd-0 text-center">
                            <p style="font-size: 20px; margin-bottom: 0px;"><strong>REASON FOR WATCHLIST ({{selectedClient}})</strong></p>
                            <p style="font-size: 20px; margin-bottom: 0px;">{{watchlist.reason}}</p>
                        </div>

                        <div class="card card-body tx-white-8 bg-primary bd-0 text-center" v-bind:class="{'mg-t-20': national.by.length || watchlist !== null}">
                            <p style="font-size: 20px; margin-bottom: 0px;"><strong>CLAIM OVERVIEW ({{selectedClient}})</strong></p>
                            <p style="font-size: 20px; margin-bottom: 0px;">{{watchlist.claims}} Claims Outstanding</p>
                            <p style="font-size: 20px; margin-bottom: 0px;">£{{watchlist.value || 0}} Owed</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row mg-t-20">
            <div v-if="$can('events-unblacklist') || $can('events-blacklist')" class="col-6">
                <div class="card mg-b-20">
                    <div class="card-header text-white">
                        Watchlist
                    </div>
                    <div class="card-body" v-show="!loading">

                        <button v-if="watchlist.reason !== null && watchlist.created_at !== null" @click="unwatchlistVehicle" class="btn btn-sm btn-success">Remove from Blacklist</button>
                        <button v-else class="btn btn-sm btn-danger" @click="watchlistVehicle">Add to Blacklist</button>

                    </div>
                    <div class="card-body" v-if="loading">
                        <div class="d-flex ht-300 pos-relative align-items-center">
                            <div class="sk-folding-cube">
                                <div class="sk-cube1 sk-cube"></div>
                                <div class="sk-cube2 sk-cube"></div>
                                <div class="sk-cube4 sk-cube"></div>
                                <div class="sk-cube3 sk-cube"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mg-b-20">
                    <div class="card-header text-white">
                        Notes
                    </div>
                    <div class="card-body" v-show="!loading">

                        <div class="br-chat-body" style="max-height: 500px; width: 100%; overflow-y: scroll;">
                            <p v-if="vehicle.notes.length < 1">No notes for this vehicle!</p>
                            <div v-for="n in vehicle.notes" v-bind:class="{'flex-row-reverse': n.user.id === user.id}" class="media">
                                <div class="media-body">
                                    <div class="msg-wrapper">
                                        {{n.note}}
                                    </div><!-- msg-wrapper -->
                                    <div>{{ n.created_at }}&nbsp;by&nbsp;<span>{{n.user.email}}</span></div>
                                </div><!-- media-body -->
                            </div>
                        </div>

                        <br/>
                        <br/>
                        <br/>

                        <div class="br-chat-footer mg-t-20">
                            <div><input v-model="newNote" type="text" class="form-control" placeholder="Write your message here..." @keydown.enter="createNote"></div>
                            <nav class="nav">
                                <a v-if="!sendingMessage" href="javascript:void(0);" @click="createNote" class="nav-link"><i class="icon ion-android-send"></i></a>
                                <div v-else class="sk-wave">
                                    <div class="sk-rect sk-rect1 bg-white"></div>
                                    <div class="sk-rect sk-rect2 bg-white"></div>
                                    <div class="sk-rect sk-rect3 bg-white"></div>
                                    <div class="sk-rect sk-rect4 bg-white"></div>
                                    <div class="sk-rect sk-rect5 bg-white"></div>
                                </div>
                            </nav>
                        </div>

                    </div>
                    <div class="card-body" v-if="loading">
                        <div class="d-flex ht-300 pos-relative align-items-center">
                            <div class="sk-folding-cube">
                                <div class="sk-cube1 sk-cube"></div>
                                <div class="sk-cube2 sk-cube"></div>
                                <div class="sk-cube4 sk-cube"></div>
                                <div class="sk-cube3 sk-cube"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="$can('events-unblacklist') || $can('events-blacklist')" class="col-6">
                <div class="card mg-b-20">
                    <div class="card-header text-white">
                        Customer Service Message
                    </div>
                    <div class="card-body" v-show="!loading">

                        <p>If you would like the display to notify staff of something when this vehicle comes onto site
                        but do not wish to blacklist it, place a message below.</p>

                        <p class="tx-danger">If the vehicle is <b>blacklisted</b> this message will not show on the display. Please use the notes if you wish for it to display on a blacklist alert!</p>

                        <textarea v-model="vehicle.customerServiceMessage.message" class="form-control form-control-dark" placeholder="Write your message here..."></textarea>
                        <br/>
                        <submit-button :background="'btn-sm btn-success'" :clicked="updatingCustomerService" :text="'Save'" @click="updateCustomerServiceMessage"></submit-button>
                    </div>
                    <div class="card-body" v-if="loading">
                        <div class="d-flex ht-300 pos-relative align-items-center">
                            <div class="sk-folding-cube">
                                <div class="sk-cube1 sk-cube"></div>
                                <div class="sk-cube2 sk-cube"></div>
                                <div class="sk-cube4 sk-cube"></div>
                                <div class="sk-cube3 sk-cube"></div>
                            </div>
                        </div>
                    </div>
                </div>
<!--                <div class="card">-->
<!--                    <div class="card-header text-white">-->
<!--                        Vehicle Overview-->
<!--                    </div>-->
<!--                    <div class="card-body" v-if="overviewLoaded">-->

<!--                        <div class="row row-sm">-->
<!--                            <div class="col-sm-6 col-lg-4">-->
<!--                                <div class="card bg-white shadow-base bd-0">-->
<!--                                    <div class="card-header d-flex justify-content-between align-items-center">-->
<!--                                        <h6 class="card-title tx-uppercase tx-12 mg-b-0">Drive Offs</h6>-->
<!--                                        <span class="tx-12 tx-uppercase">LIFETIME</span>-->
<!--                                    </div>&lt;!&ndash; card-header &ndash;&gt;-->
<!--                                    <div class="card-body d-xs-flex justify-content-between align-items-center">-->
<!--                                        <h4 class="mg-b-0 tx-inverse tx-lato tx-bold">{{overview.driveoff.toLocaleString()}}</h4>-->
<!--                                    </div>&lt;!&ndash; card-body &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; card &ndash;&gt;-->
<!--                            </div>&lt;!&ndash; col-4 &ndash;&gt;-->
<!--                            <div class="col-sm-6 col-lg-4 mg-t-20 mg-sm-t-0">-->
<!--                                <div class="card bg-white shadow-base bd-0">-->
<!--                                    <div class="card-header d-flex justify-content-between align-items-center">-->
<!--                                        <h6 class="card-title tx-uppercase tx-12 mg-b-0">NMOP</h6>-->
<!--                                        <span class="tx-12 tx-uppercase">LIFETIME</span>-->
<!--                                    </div>&lt;!&ndash; card-header &ndash;&gt;-->
<!--                                    <div class="card-body d-xs-flex justify-content-between align-items-center">-->
<!--                                        <h4 class="mg-b-0 tx-inverse tx-lato tx-bold">{{overview.nmop.toLocaleString()}}</h4>-->
<!--                                    </div>&lt;!&ndash; card-body &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; card &ndash;&gt;-->
<!--                            </div>&lt;!&ndash; col-4 &ndash;&gt;-->
<!--                            <div class="col-sm-6 col-lg-4 mg-t-20 mg-lg-t-0">-->
<!--                                <div class="card bg-white shadow-base bd-0">-->
<!--                                    <div class="card-header d-flex justify-content-between align-items-center">-->
<!--                                        <h6 class="card-title tx-uppercase tx-12 mg-b-0">Parking</h6>-->
<!--                                        <span class="tx-12 tx-uppercase">LIFETIME</span>-->
<!--                                    </div>&lt;!&ndash; card-header &ndash;&gt;-->
<!--                                    <div class="card-body d-xs-flex justify-content-between align-items-center">-->
<!--                                        <h4 class="mg-b-0 tx-inverse tx-lato tx-bold">{{overview.parking.toLocaleString()}}</h4>-->
<!--                                    </div>&lt;!&ndash; card-body &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; card &ndash;&gt;-->
<!--                            </div>&lt;!&ndash; col-4 &ndash;&gt;-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="card-body" v-if="!overviewLoaded">-->
<!--                        <div class="d-flex ht-300 pos-relative align-items-center">-->
<!--                            <div class="sk-folding-cube">-->
<!--                                <div class="sk-cube1 sk-cube"></div>-->
<!--                                <div class="sk-cube2 sk-cube"></div>-->
<!--                                <div class="sk-cube4 sk-cube"></div>-->
<!--                                <div class="sk-cube3 sk-cube"></div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import Loader from "@/components/Loader";
import SubmitButton from "@/components/SubmitButton";
import Swal from 'sweetalert2';
import SecureImage from "@/components/SecureImage";
import axios from 'axios';
import authHeader from '@/services/auth-header';
import { DateTime } from "luxon";

export default {
    name: "Event",
    components: {SecureImage, SubmitButton, Loader},
    data(){
        return {
            socket_token: "",
            overviewLoaded: false,
            event: {
                id: -1,
                site_id: -1
            },
            vehicle: {
                notes: [],
                customerServiceMessage: {
                    message: ""
                }
            },
            camera: {},
            site: {},
            watchlist: {},
            loading: true,
            national: {},
            blacklist: {
                created_at: null,
                reason: null,
            },
            newNote: "",
            sendingMessage: false,
            overview: {
                parking: 0,
                nmop: 0,
                driveoff: 0,
            },
            plateHighlight: {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
                magnify: 3,
                canvas: null
            },
            customer_service: {
                id: null,
                vehicle_id: null,
                created_at: null,
                message: null
            },
            updatingCustomerService: false,

        }
    },
    mounted() {
        this.event.id = parseInt(this.$route.params.id || -1);
        this.socket_token = this.$store.state.auth.user.socket_token;
        axios.get(`https://api.varsanpr.com/api/event/${this.event.id}?client_id=${this.$store.state.auth.user.selectedClient}`, {
            headers: authHeader()
        })
            .then(response => {
                this.event = response.data;
                if(!response.data.vehicle.customerServiceMessage){
                    response.data.vehicle.customerServiceMessage = {
                        message: ""
                    }
                }
                this.vehicle = response.data.vehicle;
                this.camera = response.data.camera;
                this.site = response.data.site;
                this.national = response.data.blacklist.national;
                this.watchlist = response.data.blacklist.local;
                this.customer_service = response.data.vehicle.customerServiceMessage;
                this.loading = false;
                this.vehicle.notes.forEach((note) => {
                    note.created_at = DateTime.fromSeconds(note.created_at).toFormat('dd/MM/yyyy HH:mm:ss');
                })
                this.showHighlight();
            })
            .catch(error => {
            });
    },
    methods: {
        showHighlight(){
              this.plateHighlight.canvas = document.getElementById('plateCanvas');
              if(this.event.coordinates === undefined){
                  console.log("No coordinates");
                  return;
              }
              let coordinates = this.event.coordinates.replace(/\[/g, '').replace(/\]/g, '');
              coordinates = coordinates.split(",");
              this.plateHighlight.x = coordinates[0] -20;
              if(this.plateHighlight.x < 0) this.plateHighlight.x = 0;
              this.plateHighlight.y = coordinates[1] -20;
            if(this.plateHighlight.y < 0) this.plateHighlight.y = 0;
              this.plateHighlight.width = Math.abs(coordinates[0] - coordinates[2]) + 20;
              this.plateHighlight.height = Math.abs(coordinates[1] - coordinates[3]) + 20;
              console.log(coordinates);
            const image = document.getElementById('event_image');
            const ctx = this.plateHighlight.canvas.getContext('2d');

            ctx.drawImage(image, this.plateHighlight.x, this.plateHighlight.y, this.plateHighlight.width, this.plateHighlight.height, 0, 0, this.plateHighlight.width * this.plateHighlight.magnify, this.plateHighlight.height * this.plateHighlight.magnify);
        },
        updateCustomerServiceMessage(){
              this.updatingCustomerService = true;
                axios.put(`https://api.varsanpr.com/api/vehicle/${this.event.vehicle_id}/csm`, {
                    message: this.vehicle.customerServiceMessage.message,
                    client_id: this.$store.state.auth.user.selectedClient
                }, {
                    headers: authHeader(),
                    params: {
                        client_id: this.$store.state.auth.user.selectedClient
                    }
                })
                    .then(response => {
                        this.updatingCustomerService = false;
                        this.$success(`Successfully updated customer service message for ${this.vehicle.plate}`)
                    })
                    .catch(error => {
                        this.$error("Unable to update Vehicle's customer service message!", error);
                        this.updatingCustomerService = false;
                    })
        },
        createNote(){
            this.sendingMessage = true;
            if(this.newNote.length < 1){
                this.$error("Please provide a valid note.");
                return;
            }
            axios.put(`https://api.varsanpr.com/api/vehicle/${this.vehicle.id}/notes?client_id=${this.$store.state.auth.user.selectedClient}`, {
                note: this.newNote,
            }, {
                headers: authHeader()
            })
                .then(response => {
                    this.vehicle.notes = response.data.note;
                    this.vehicle.notes.forEach((note) => {
                        note.created_at = DateTime.fromSeconds(note.created_at).toFormat('dd/MM/yyyy HH:mm:ss');
                    })
                    this.newNote = "";
                    this.sendingMessage = false;
                })
                .catch(error => {
                    this.$error("Unable to save Note to Vehicle.", error);
                    this.sendingMessage = false;
                })
        },
        async watchlistVehicle(e){
            const {value: reason} = await Swal.fire({
                title: `Select a reason for blacklisting ${this.vehicle.plate}`,
                input: 'select',
                inputOptions: {
                    "Drive Off": "Drive Off",
                    "No Means of Payment": "No Means of Payment",
                    "Fly Tipping": "Fly Tipping",
                    "Theft": "Theft",
                    "Aggressive": "Aggressive",
                    "Plate Mismatch": "Plate Mismatch"
                },
                inputPlaceholder: 'Select a reason',
                showCancelButton: true
            });

            if(reason){
                axios.put(`https://api.varsanpr.com/api/blacklist`, {
                    client_id: this.$store.state.auth.user.selectedClient,
                    registration: this.vehicle.plate,
                    reason: reason
                }, {
                    headers: authHeader()
                })
                    .then(response => {
                        this.watchlist = {
                            reason: reason,
                        }
                        this.$success(`Added ${this.vehicle.plate} to blacklist`);
                    })
                    .catch(error => {
                        console.error("Failed adding to blacklist");
                        this.$error("Failed adding to blacklist", error);
                    });
            }
        },
        unwatchlistVehicle(){
            axios.delete(`https://api.varsanpr.com/api/blacklist`, {
                data: {
                    client_id: this.$store.state.auth.user.selectedClient,
                    registration: this.vehicle.plate
                },
                headers: authHeader()
            })
                .then(response => {
                    this.watchlist = {
                        reason: null
                    };
                    this.$success(`Removed ${this.vehicle.plate} from watchlist`);
                })
                .catch(error => {
                    console.error("Failed removing from watchlist");
                    this.$error("Failed removing from watchlist", error);
                });
        }
    }
}
</script>

<style scoped>
.number-plate
{
    background-color: #f8ef1f;
    border-radius: 0.25rem;
    color: black;
    display: inline-block;
    font-family: "Arial Black";
    font-weight: bold;
    padding: 0.1rem 0.9rem;
    font-size: 1.5rem;
}

.number-plate-gbr
{
    background-image: url(/img/uk-plate-logo.png);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: auto 100%;
    padding-left: 2rem;
}
 .img-magnifier-container {
     position: relative;
 }

</style>
